const ERR_1 = 'ErrFram'
const ERR_2 = 'ErrFlash'
const ERR_3 = 'ErrLogImage'
const ERR_4 = 'ErrLogFormat'
const ERR_5 = 'ErrSettings'
const ERR_6 = 'Err6LowPan'
const ERR_7 = 'OK'
const ERR_8 = 'NOT_IN_TOUCH'
const ERR_9 = 'SUPPLY_OFF'

export const CB_STATUSES = Object.freeze([
  {
    title: ERR_1,
    type: 1,
  },
  {
    title: ERR_2,
    type: 2,
  },
  {
    title: ERR_3,
    type: 3,
  },
  {
    title: ERR_4,
    type: 4,
  },
  {
    title: ERR_5,
    type: 5,
  },
  {
    title: ERR_6,
    type: 6,
  },
  {
    title: ERR_7,
    type: 7,
  },
  {
    title: ERR_8,
    type: 8,
  },
  {
    title: ERR_9,
    type: 9,
  },
])
