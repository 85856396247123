import { CB_STATUSES } from '@/const/monitoringCbStatuses.enum'

export default {
  filters: {
    getCbStatusTitle(val) {
      const currentCbStatus = CB_STATUSES.filter((status) => status.type === val)
      if (currentCbStatus.length) {
        return currentCbStatus[0].title
      } else return val || 'Статуси не надходили'
    },
  },
}
